import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/components/render-from-template-context.js");
